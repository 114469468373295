


:root {
  --background-white: white;
  --text-white: white;
  --background-black: black;
  --text-color: #676767;
  --heading-font-weight: 600;
  --body-font-weight: 400;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-600: #4b5563;
  --hr-border: #e5e7eb;
  --gray-500: #6b7280;
  --gray-400: #bdbdbd;
  --gray-700: #374151;
  --primary-color: #212d45;
  --secondary-color: #f8b526;;
  --skeleton-color: #d1d5db;
}

.overlay-four {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #f7fff9;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.menu-list {
  display: flex;
  flex-direction: column;
  gap: 2px;
}


 
.slide-in {
  animation: slideIn 0.3s ease-in-out forwards;
}

.slide-out {
  animation: slideOut 0.3s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}


@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: inline-block;
  animation: marquee 200s linear infinite;
}

